.topbar-right .social-icons li {
  margin-left: 0.75rem;
}

.ttm-header-style-classicinfo .ttm-content-wrapper {
  background-color: #ffffff;
}

.site__branding__img {
  max-height: 180px !important;
  max-width: 65% !important;
  background-size: cover !important;
  -o-object-fit: contain !important;
  object-fit: contain !important;
  -webkit-transform: translateX(10px) !important;
  transform: translateX(10px) !important;
  margin-top: -14px;
}

.header__container .col__hide .ttm-info-widget {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header__container .col__hide .ttm-info-widget img {
  max-width: 100%;
  height: 76px;
}

.header__container .slogan__wrapper {
  overflow: hidden;
  padding-top: 2rem !important;
}

.header__container .slogan__text {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-size: 1.25em;
  font-style: italic;
  text-align: center;
  color: red;
  text-shadow: 1px 1px 2px #df5959;
  -webkit-animation: bouncing-text 8s linear infinite forwards;
  animation: bouncing-text 20s linear infinite forwards;
}

.header__container .slogan__text:hover {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

@-webkit-keyframes bouncing-text {
  0% {
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
  }
  100% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

@keyframes bouncing-text {
  0% {
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
  }
  100% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

/* Media Queries */
@media screen and (max-width: 1199px) {
  .header__container .col__hide {
    display: none !important;
  }
  .header__container .slogan__wrapper {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}

.site-main .featured-icon-box .featured-icon-box-inner {
  height: 200px;
}

.site-main .featured-icon-box .featured-icon-box-inner img {
  width: 65px;
  margin-top: -10px;
}

.site-main .ttm_single_image-wrapper img {
  max-width: 100%;
  height: 550px;
}

.site-main .introduction-section {
  margin-bottom: 4rem;
}

#rev_slider_4_2_wrapper img {
  max-width: 100%;
}

#content-slider h1.main-title {
  margin-bottom: 2rem;
}

#computer-courses section.ttm-row.introduction-section {
  padding: 80px 0 0 !important;
}

#page-offline {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

#page-offline h1 {
  margin-bottom: 4rem;
  font-size: 50px;
}

#page-offline p {
  margin-bottom: 4rem;
}

#page-offline p i {
  font-size: 16rem;
  color: #033b4a;
}

.scrolling__news__wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#05a2db),
    to(#066588)
  );
  background: linear-gradient(to right, #05a2db, #066588);
}

.scrolling__news__wrapper .label {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 1.2rem;
  -ms-flex-preferred-size: 10%;
  flex-basis: 10%;
  padding: 0.5rem 1rem;
  color: #fff;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#05a2db),
    to(#066588)
  );
  background: linear-gradient(to right, #05a2db, #066588);
}

.scrolling__news__wrapper .text__wrapper {
  position: relative;
  padding: 0.5rem 1rem;
  text-align: center;
  -ms-flex-preferred-size: 90%;
  flex-basis: 90%;
  overflow: hidden;
}

.scrolling__news__wrapper p {
  position: absolute;
  top: 0.5rem;
  left: 0;
  cursor: pointer;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 1.2rem;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: 100%;
  color: #fff;
  -webkit-animation: animate-marque 30s linear infinite forwards;
  animation: animate-marque 30s linear infinite forwards;
}

.scrolling__news__wrapper p:hover {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

@-webkit-keyframes animate-marque {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@keyframes animate-marque {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

a.btn__appointment {
  position: relative;
  background-color: #191919;

  border: none;
  outline: none;
  z-index: 1;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  padding-right: 65px !important;
  padding-left: 72px !important;
  cursor: pointer;
}

a.btn__appointment::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: 1px solid #1f9bd5;

  z-index: -1;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: left;
  transform-origin: left;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

a.btn__appointment:hover,
a.btn__appointment:focus {
  color: #000 !important;
}

a.btn__appointment:hover::after,
a.btn__appointment:focus::after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: left;
  transform-origin: left;
}

.navbar-toggler {
  position: relative;
  width: 30px;
  height: 20px;
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
}

.navbar-toggler span {
  display: inline-block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #fff;
}

.navbar-toggler span:nth-child(1) {
  top: 0;
}

.navbar-toggler span:nth-child(2) {
  top: 8px;
}

.navbar-toggler span:nth-child(3) {
  bottom: 0;
}
